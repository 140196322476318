export default {
  "dobro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добро."])},
  "chesnist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чесність."])},
  "gra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гра."])},
  "description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Це інтерактивна пригода, в якій від вас залежить доля цілого регіону. Обирайте будь-яку область і розв'язуйте проблеми, які в ній виникають. Чи варто персонажу йти працювати до родичів? Як перемогти корупцію в освіті? Що робити з коштовним подарунком?"])},
  "description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слідкуйте за показниками Законності та Привабливості регіону, тому що саме від них залежить ваш фінальний бал — а він впливає на місце вашої області у всеукраїнському рейтингу доброчесності!"])},
  "description-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бажаємо вам вдалої гри, адже тільки ви вирішуєте, яким стане ваш регіон — чи діють в ньому норми закону, чи захочеться тут взагалі жити та як високо підніметься ваш край у загальному змаганні!"])},
  "start-game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почати гру!"])},
  "kadentsiya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша каденція розпочинається"])},
  "finish-text-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Окей-окей, це була всього лише гра. Або ні."])},
  "finish-text-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми запамʼятали вас, але як? Ви взірцевий борець з корупцією чи хабарник, патріот чи популіст? Давайте дізнаємось, як ваші вибори вплинули на рейтинг області"])},
  "finish-text-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ось чого вам вдалося досягти:"])},
  "finish-text-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🥳 Ваша каденція увійшла у "])},
  "finish-text-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" найбільш доброчесних"])},
  "pryvablyvist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привабливість"])},
  "zakonnist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Законність"])},
  "resultaty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переглянути результати"])},
  "description-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Натискайте на проблеми, що з'являються на мапі, щоб спробувати їх розвʼязати. Читайте, що сталося і обирайте найкращий, на вашу думку, варіант відповіді. Пам'ятайте, що тільки від вас залежить, як зміниться ситуація в регіоні!"])},
  "summary-good-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Йой! Ви молодець!"])},
  "summary-bad-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Йой! Що ж так погано?"])},
  "summary-good-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дякуємо, ваша каденція завершується! Треба визнати, ви впоралися чудово — хай війна з корупцією в Україні й триває, але область під вашим керівництвом свою битву виграла. І це точно допоможе рейтингу регіону у всеукраїнському змаганні. Люди оспівують ваше ім'я як живого символа доброчесності і завжди чекатимуть вашого повернення у владу!"])},
  "summary-bad-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша каденція завершилася! Люди щасливі — але тільки тому, що цей жах нарешті скінчився. На жаль, під вашим керівництвом ситуація лише погіршилася, що неодмінно вплине на рейтинг регіону у всеукраїнському змаганні. Шкода, але навряд чи хтось складе пісні про вашу доброчесність — хіба що за хабар"])},
  "do-ratingu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До загального рейтингу"])},
  "zmina-mistsya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зміна місця в таблиці"])},
  "oberit-variant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОБЕРІТЬ ВАРІАНТ ДІЇ"])},
  "zrozumilo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зрозуміло"])},
  "feedback-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибір зроблено! Та чи правильний він?"])},
  "feedback-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вас почули"])},
  "feedback-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хай буде так!"])},
  "feedback-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цікаве рішення"])},
  "feedback-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прийнято"])},
  "feedback-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Який же вплив це матиме?"])},
  "feedback-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Що ж, ви тут головні"])},
  "footer-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гра створена за підтримки:"])},
  "more-games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Більше ігор"])},
  "more-games-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спробуйте інші наші ігри про громадську активність та особисту безпеку під час війни!"])},
  "feedback-form-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маєте що сказати? 🧐"])},
  "feedback-form-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розкажіть нам як зробити гру ще краще"])},
  "rating-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РЕЙТИНГ ДОБРОЧЕСНОСТІ"])},
  "show-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПОКАЗАТИ ВСІ"])},
  "show-less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПОКАЗАТИ МЕНШЕ"])},
  "share-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сподобалася гра?"])},
  "share-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поділись грою із друзями, щоб саме твій регіон став першим й рейтингу доброчесності!"])},
  "messages-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вітаю"])},
  "messages-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Це анонімний доброзичливець. Не можу довго говорити, але зробіть щось з Законністю"])},
  "messages-1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Там жах. Інакше буде погано!"])},
  "messages-2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привіт"])},
  "messages-2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Це ваш помічник"])},
  "messages-2-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колишній помічник. Бо Законність впала так низько, що я вкрав ваш сейф і втік. Гарного дня!"])},
  "messages-3-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Питання:"])},
  "messages-3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Що відбувається із суспільством, коли закони не діють?"])},
  "messages-3-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нічого хорошого, але ви все ж загнали Законність на самісіньке дно!"])},
  "messages-4-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["👋"])},
  "messages-4-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показники Привабливості настільки погані, що жителі планують протести під вашими вікнами"])},
  "messages-4-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Головне, щоб не спіймали, адже тоді ці акції можуть стати насильницькими і дуже неприємними для вас особисто!"])},
  "messages-5-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доброго дня"])},
  "messages-5-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привабливість — кошмар"])},
  "messages-5-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо вас не звільнить влада, то звільнять люди — і це може бути боляче!"])},
  "messages-6-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тук-тук!"])},
  "messages-6-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вранці повз нашу область проходив інший регіон, глянув і сказав:"])},
  "messages-6-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Фу, щось у вас Привабливість не дуже'. Треба це виправляти!"])},
  "messages-7-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["❤️"])},
  "messages-7-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завтра краще з дому через чорний хід"])},
  "messages-7-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бо ситуація в області настільки хороша, що вдячні жителі хочуть особисто вам подякувати. На радощах можуть заобіймати, а у вас ще купа справ!"])},
  "messages-8-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["👋"])},
  "messages-8-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви що, чіт-кодами користуєтеся?"])},
  "messages-8-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бо інакше жителі не можуть пояснити дивовижний розквіт, що переживає область завдяки вам!"])},
  "messages-9-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привіт!"])},
  "messages-9-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Це кур'єр 'Відносно нової пошти' — вам тут посилка"])},
  "messages-9-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["І вона гігантська, бо всередині — листи подяки від людей, які обожнюють вас за такі високі показники."])},
  "vyrisheno-problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вирішено проблем:"])},
  "kadentsiya-start-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автономна республіка у ваших руках, приймайте рішення сумлінно та совісно, щоб не сісти за грати. Ось що вам дісталося від попередників:"])},
  "kadentsiya-start-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Область у ваших руках, приймайте рішення сумлінно та совісно, щоб не сісти за грати. Ось що вам дісталося від попередників:"])},
  "poperednyky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кляті попередники!"])},
  "molodec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я молодець!"])},
  "ne-molodec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Треба було раніше думати"])},
  "obery-oblast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обери область"])},
  "more-resourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["БІЛЬШЕ КОРИСНИХ РЕСУРСІВ"])},
  "more-resourses-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основи антикорупції для всіх і кожного"])},
  "more-resourses-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінюй громаду і країну!"])},
  "more-resourses-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YOUніверситет"])},
  "more-resourses-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ще більше курсів з теми антикорупції та доброчесності"])}
}