<template>
  <button
    :class="cssClasses"
    class="btn"
    :disabled="disabled"
    @click.once="clickHandler"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    wide: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
    bad: {
      type: Boolean,
      default: false,
    },
    good: {
      type: Boolean,
      default: false,
    },
    main: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    cssClasses() {
      return {
        "disabled-btn": this.disabled,
        "white-btn": this.white,
        "good-btn": this.good,
        "bad-btn": this.bad,
        "main-btn": this.main,
      };
    },
  },
  methods: {
    clickHandler() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="sass">
.button-wrapper
  position: relative

.btn
  display: flex
  align-items: center
  justify-content: center
  position: relative
  padding: 15px 40px
  background-color: $primary-dark
  background: $background-primary
  color: $white
  font-size: 14px
  line-height: 18px
  font-weight: 400
  cursor: pointer
  border: 0
  border-radius: 0.8rem
  width: 100%
  &:hover
    background: $primary-dark

.btn.wide
  border-radius: 0 0 4rem 4rem

.btn.white-btn
  background: $white
  color: $primary

.btn.good-btn
  background: $white
  color: #40AF81
  font-size: 16px
  font-weight: 700
  
.btn.bad-btn
  background: $white
  color: #F1744C
  font-size: 16px
  font-weight: 700

.btn.main-btn
  font-size: 2.4rem
  line-height: 2.9rem
  font-weight: 800
  &:hover
    filter: none
    background-color: $primary-dark
    color: $white


.disabled-btn
  opacity: 0.5
  cursor: default
  &:hover
    background: $primary
</style>
