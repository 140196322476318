import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/sass/main.sass";
import "./assets/sass/config/fonts.sass";
import ButtonBase from "@/elements/ButtonBase.vue";
import SvgIcon from "@/elements/SvgIcon.vue";
import i18n from './i18n';

const app = createApp(App).use(i18n)

app.component('ButtonBase', ButtonBase)
app.component('SvgIcon', SvgIcon)
app.use(store).use(router).mount('#app')
