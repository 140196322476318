<template>
  <router-view/>
</template>

<style lang="sass">

</style>

<script>

export default {
  name: "App",
  components: {
  },
  data: () => ({
  }),
  created() {
  },
  methods: {},
};
</script>


<style scoped>
#app {
  height: auto !important
}

@font-face {
  font-family: "KyivType";
  src: local("KyivType"),
    url(./assets/fonts/KyivTypeSans.ttf) format("truetype");
}

@font-face {
  font-family: "NAMU";
  src: local("NAMU"),
    url(./assets/fonts/NAMU-Pro.woff) format("woff");
}
</style>