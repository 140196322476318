export default {
  "dobro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrity."])},
  "chesnist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game."])},
  "gra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is an interactive adventure where the fate of the whole region depends on you. Choose any oblast and solve the problems that arise in it. Should your character work for their relatives? How to defeat corruption in education? What will you do with a costly present?"])},
  "description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitor the Legitimacy and Attractiveness indicators of the region, as your final score depends on them, which in turn affects your oblast’s place in the all-Ukrainian integrity rating!"])},
  "description-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We wish you a successful game, because only you decide what your region will be like — whether the law is in force, whether people want to live here at all, and how high your region will rise in the overall competition!"])},
  "start-game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start the game!"])},
  "kadentsiya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your tenure begins"])},
  "finish-text-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay, okay. It was just a game. Or not."])},
  "finish-text-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We remembered you, but how? Are you an exemplary fighter against corruption or a bribe-taker, a patriot or a populist? Let’s find out how the choices that you have made influenced the oblast’s rating."])},
  "finish-text-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here is what you managed to achieve:"])},
  "finish-text-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your tenure was included in the "])},
  "finish-text-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" of the most integrity!"])},
  "pryvablyvist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attractiveness"])},
  "zakonnist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legitimacy"])},
  "resultaty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View results"])},
  "description-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the problems that appear on the map to try to solve them. Read what happened and choose the best answer in your opinion. Remember that it depends only on you how the situation in the region will change!"])},
  "summary-good-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wow! You did great!"])},
  "summary-bad-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yikes! What went wrong?"])},
  "summary-good-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you, your term is over! Gotta admit, you did a great job. Even though the war against corruption in Ukraine continues, your oblast won its battle under your leadership. And it will certainly help the region’s rating in the all-Ukrainian competition. People chant your name as a living symbol of integrity and will always await your return to power!"])},
  "summary-bad-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your term has ended! People are happy, but only because this horror is finally over. Unfortunately, the situation under your leadership only got worse, which will certainly impact the region’s rating in the all-Ukrainian competition. Too bad, but it’s unlikely that anyone will chant your integrity, unless they’re bribed"])},
  "do-ratingu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to overall rating"])},
  "zmina-mistsya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking Changes"])},
  "oberit-variant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHOOSE ACTION"])},
  "zrozumilo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Understood"])},
  "feedback-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The choice is made! But is it the right one?"])},
  "feedback-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You’ve been heard"])},
  "feedback-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May it be so!"])},
  "feedback-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interesting decision"])},
  "feedback-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed"])},
  "feedback-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What impact will it have?"])},
  "feedback-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Well, you’re in charge here"])},
  "footer-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The game is supported by:"])},
  "more-games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More games"])},
  "more-games-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out our other games about civic activism and personal safety during the war!"])},
  "feedback-form-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have anything to share? 🧐"])},
  "feedback-form-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please tell us how to improve the game even more"])},
  "rating-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INTEGRITY RATING"])},
  "show-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SHOW ALL"])},
  "show-less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SHOW LESS"])},
  "share-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did you like the game?"])},
  "share-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share it with friends to make your region the first in the integrity rating!"])},
  "messages-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations"])},
  "messages-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is an anonymous well-wisher. I can’t talk long, but do something about the Legitimacy"])},
  "messages-1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It’s a mess. Otherwise, it will be bad!"])},
  "messages-2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello"])},
  "messages-2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is your assistant"])},
  "messages-2-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Former assistant. Because the Legitimacy has fallen so low that I stole your safe and ran away. Have a good day!"])},
  "messages-3-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question:"])},
  "messages-3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What happens to society when laws don’t work?"])},
  "messages-3-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nothing good, but you made the Legitimacy hit rock bottom!"])},
  "messages-4-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["👋"])},
  "messages-4-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Attractiveness indicators are so bad that residents are planning protests under your windows"])},
  "messages-4-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be careful and don’t get caught, because these protests may become violent and very unpleasant for you personally!"])},
  "messages-5-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good afternoon"])},
  "messages-5-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Attractiveness is a nightmare here"])},
  "messages-5-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the authorities don’t fire you, then people will — and it can be painful!"])},
  "messages-6-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knock, knock!"])},
  "messages-6-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Another region was passing by our oblast this morning, it looked at it and said:"])},
  "messages-6-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Ew, your Attractiveness is bad.” This needs fixing!"])},
  "messages-7-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["❤️"])},
  "messages-7-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomorrow, it is better to leave the house through the back door"])},
  "messages-7-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because the situation in the oblast is so good that grateful residents want to thank you personally. They may hug you a little too much with joy, and you still have a lot to do!"])},
  "messages-8-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["👋"])},
  "messages-8-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you using cheat codes or something?"])},
  "messages-8-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because the residents have no other explanation for the amazing prosperity that the oblast is experiencing thanks to you!"])},
  "messages-9-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello!"])},
  "messages-9-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a Definitely Not FedEx courier. Delivery for you!"])},
  "messages-9-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And it’s gigantic because inside it are letters of gratitude from people who adore you for high results."])},
  "vyrisheno-problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problems solved:"])},
  "kadentsiya-start-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Autonomous Republic is in your hands; make decisions in good faith and clear conscience so as not to end up behind bars. Here’s what you got from your predecessors:"])},
  "kadentsiya-start-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The oblast is in your hands; make decisions in good faith and clear conscience so as not to end up behind bars. Here’s what you got from your predecessors:"])},
  "poperednyky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damned predecessors!"])},
  "molodec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I did it!"])},
  "ne-molodec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Should’ve thought earlier"])},
  "obery-oblast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose oblast"])},
  "more-resourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MORE USEFUL RESOURCES"])},
  "more-resourses-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basics of anti-corruption for everyone"])},
  "more-resourses-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the community and the country!"])},
  "more-resourses-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YOUniversity"])},
  "more-resourses-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More courses on anti-corruption and integrity"])}
}